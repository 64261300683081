var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-bottom-sheet',{attrs:{"id":"embedded-botton","hide-overlay":"","width":"350px","persistent":"","scrollable":"","round":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"embedded",attrs:{"fab":"","dark":"","large":"","color":"#00a7e4"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-phone-outgoing ")])],1)]}}]),model:{value:(_vm.sheet),callback:function ($$v) {_vm.sheet=$$v},expression:"sheet"}},[_c('v-sheet',{staticClass:"pl-2 pr-2",staticStyle:{"border-radius":"6px"},attrs:{"height":"420px","color":"#00a7e4","flat":""}},[_c('v-card',{attrs:{"height":"410","round":"","flat":""}},[_c('v-toolbar',{attrs:{"flat":"","color":"#00a7e4","dark":""}},[_c('v-toolbar-title',{staticClass:"text-center"},[_vm._v("Call it Once")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.sheet = !_vm.sheet}}},[_c('v-icon',[_vm._v("mdi-chevron-down")])],1)],1),_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(this.$route.query.llamadaMasiva !== 'true'),expression:"this.$route.query.llamadaMasiva !== 'true'"}],staticClass:"mt-5",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"11"}},[_c('v-select',{attrs:{"label":"Campañias","color":"#00a7e4","outlined":"","clearable":"","items":_vm.campaingsSelected,"hide-details":"","dense":"","item-value":"id","disabled":this.llamar !== false},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('strong',[_vm._v(_vm._s(_vm.formatText(item)))])])]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"11px"}},[_vm._v(" "+_vm._s(_vm.formatText(item))+" ")])]}}]),model:{value:(_vm.type_campaing),callback:function ($$v) {_vm.type_campaing=$$v},expression:"type_campaing"}})],1)],1),(_vm.type_campaing != null || this.$route.query.llamadaMasiva == 'true')?_c('v-card-text',[_c('v-row',{staticClass:"mt-1 mr-5",attrs:{"justify":"space-around"}},[_c('v-col',{attrs:{"cols":"1"}},[_c('v-btn',{attrs:{"id":"botton-call","fab":"","small":"","color":"success","disabled":_vm.llamar || this.selectPhone == null},on:{"click":_vm.callNumber}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-phone")])],1)],1),_c('v-col',{attrs:{"cols":"1"}},[_c('v-btn',{attrs:{"fab":"","small":"","color":"red","disabled":!_vm.colgar},on:{"click":_vm.stopCall}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-phone-hangup")])],1)],1)],1),_c('v-form',{ref:"formDisposicion"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{attrs:{"label":"Tipos de contacto","color":"#00a7e4","outlined":"","clearable":"","items":_vm.TiposContactos,"hide-details":"","dense":"","rules":[_vm.$rules.required],"disabled":!_vm.llamar},model:{value:(_vm.tipo_contacto),callback:function ($$v) {_vm.tipo_contacto=$$v},expression:"tipo_contacto"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{attrs:{"label":"Disposición","clearable":"","color":"#00a7e4","outlined":"","items":_vm.disposicionesAux,"hide-details":"","dense":"","disabled":_vm.tipo_contacto == null,"item-text":"disposicion","item-value":"id","rules":[_vm.$rules.required]},model:{value:(_vm.disposicion),callback:function ($$v) {_vm.disposicion=$$v},expression:"disposicion"}})],1)],1),_c('v-row',{staticClass:"mt-2 mr-4",attrs:{"justify":"space-around","align":"center"}},[_c('v-col',{attrs:{"cols":"1"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":"","x-large":""}},[_vm._v("mdi-calendar-month")])],1)]}}],null,false,2368287684)},[_c('span',[_vm._v("Agendar llamada")])])],1)],1),(_vm.registrarAgenda)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Fecha de agenda","type":"datetime-local","hide-details":"","color":"#00a7e4","outlined":"","rules":[_vm.$rules.required]},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.cancelAgenda}},[_c('v-icon',[_vm._v("mdi-close-circle-outline")])],1)]},proxy:true}],null,false,1364021326),model:{value:(_vm.dateAgenda),callback:function ($$v) {_vm.dateAgenda=$$v},expression:"dateAgenda"}})],1)],1):_vm._e()],1)],1):_vm._e(),(_vm.isVenta)?[_c('v-card-actions',{staticClass:"ml-2 dd-flex justify-center text-center"},[(
              _vm.roles == 'AGENTCC' ||
                _vm.roles == 'AGENTCCSPONSORS' ||
                _vm.roles == 'OPERADOR' ||
                _vm.roles == 'OPERADORCC' ||
                _vm.roles == 'ADMIN' ||
                _vm.roles == 'RENOVACIONESCC'
            )?_c('v-btn',{attrs:{"dark":"","color":"primary","small":"","disabled":_vm.validarLead},on:{"click":_vm.openPolizaModal}},[_vm._v(" Crear Póliza ")]):_vm._e()],1)]:_vm._e()],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }