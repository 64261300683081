<template>
  <div>
    <CommonExpansionPanel>
      <template v-slot:title-panel>
        Datos de contacto
      </template>
      <v-expansion-panel-content>
        <view-data v-if="switch_data == 1" :personalData="personalData" @showModal="$emit('showModal')">
        </view-data>
      </v-expansion-panel-content>
    </CommonExpansionPanel>
  </div>
</template>

<script>
import CommonExpansionPanel from "@/components/commonComponents/CommonExpansionPanel.vue";
import ViewData from "./ViewData.vue";

export default {
  components: {
    CommonExpansionPanel,
    ViewData,
  },
  props: {
    personalData: Object,
    stateList: Array,
    routesConfig: Object,
    canEdit: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      switch_data: 1,
      listings: {
        canalList: [],
        ramoList: [],
        campaniaList: [],
        roles: [],
        status: [],
        landings: [],
        fuenteIngresoItem: [],
        asociadoPromotoriaItems: [],
        tipoAgenteItems: [],
      },
      config: {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      },
      roles: [],
      rol: "",
    };
  },

  methods: {

  },
  mounted() {

  },
};
</script>
